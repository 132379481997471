import React from 'react';
import styled from 'styled-components'
import face from './face-min.png'

const StyledHeader = styled.header`
  font-size: 1.5em;
  text-align: center;
  `

const StyledNav = styled.nav`
  font-size: 1em;
  text-align: center;
`

const NavItems = styled.ul`

  display: inline-block;
  padding: 0;
  list-style: none;
  float: center;
`;

const NavItem = styled.li`
  color: gray;
`;

const FaceImage = styled.img`
  width: 50%;
  max-width: 400px;
  border: 2px solid black;
  box-shadow: -5px 5px 0px 0px black;
  transition: 1s all;
`

const StyledFooter = styled.footer`
  font-size: 1em;
  text-align: center;
`



function App() {
  return (
    <div className="App">
      <StyledHeader>
          <p>new site under construction</p>
            <FaceImage src={face} width="480"/>
      </StyledHeader>
      <StyledNav>
        <NavItems>
          <NavItem>{`<ill_eventually_put_cool_stuff_here>`}</NavItem>
        </NavItems>
      </StyledNav>
      <StyledFooter><p>© mikekimland</p></StyledFooter>
    </div>
  );
}

export default App;
